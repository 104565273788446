import React from 'react'
import User from './User'
import useRoles from '../hooks/useRoles'

const Subscribers = () => {
  const roles = useRoles('subscriber')

  return (
    <div className="Subscribers">
      {roles.map(role => (
        <User key={role.uid} {...role}/>
      ))}
    </div>
  )
}

export default Subscribers