import React from 'react'
import CloudReducer, { QuerySnapshot } from './CloudReducer'
import air from '../state/firebaseApp'

export type RoleTypes = 'unknown' | 'subscriber' | 'terminal' | 'weighbridge' | 'identity' | 'debug'

export type Role = {
  uid: string
  email: string
  name: string
  role: RoleTypes
  scope?: string[]
}

const reducer = (prev: Role[], action: QuerySnapshot | null) => CloudReducer(prev, action)

const useRoles = (role: RoleTypes) => {
  const [roles, setRoles] = React.useReducer(reducer, [])

  React.useEffect(() => {
    return air.firestore()
      .collection('roles')
      .where('role', '==', role)
      .onSnapshot(setRoles)
  }, [role])

  return [...roles].sort((a, b) => {
    if (a.name && b.name) {
      return a.name.localeCompare(b.name)
    }

    return a.uid.localeCompare(b.uid)
  })
}

export default useRoles