import React from 'react'
import { RoleTypes, Role } from '../hooks/useRoles'

const EXPECT_SIGN_IN_STORAGE_KEY = 'expectSignedIn'
export const AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED'
export const ROLES_UPDATE = 'ROLES_UPDATE'

export type GlobalState = {
  expectSignedIn: boolean
  user: firebase.User | null
  users: Role[]
}

export const initialState: GlobalState = {
  expectSignedIn: localStorage.getItem(EXPECT_SIGN_IN_STORAGE_KEY) === 'true',
  user: null,
  users: []
}

type GlobalContext = {
  state: GlobalState
  dispatch: (action: Actions) => void
}

export const GlobalContext = React.createContext<GlobalContext>({
  state: initialState,
  dispatch: (action: Actions) => { },
})

type Actions = { type: typeof AUTH_STATE_CHANGED, user: firebase.User | null }
  | { type: typeof ROLES_UPDATE, users: Role[], role: RoleTypes }

const authReducer = (state: GlobalState, action: Actions): GlobalState => {
  switch (action.type) {
    case AUTH_STATE_CHANGED:
      localStorage.setItem(EXPECT_SIGN_IN_STORAGE_KEY, action.user === null ? 'false' : 'true')

      return {
        ...state,
        user: action.user,
        expectSignedIn: action.user !== null,
      }
  }

  return state
}

const mainReducer = (state: GlobalState, action: Actions): GlobalState => {
  switch (action.type) {
    case ROLES_UPDATE:
      return {
        ...state,
        users: [
          ...state.users.filter(item => item.role !== action.role),
          ...action.users,
        ]
      }
  }
  return state
}

const reducers = [
  authReducer,
  mainReducer,
]

export const reducer = (state: GlobalState, action: Actions): GlobalState => {
  return reducers.reduce((prevState, nextReducer) => nextReducer(prevState, action), state)
}