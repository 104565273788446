import React from 'react'
import { Role } from '../../hooks/useRoles'
import useAccountManager from '../../hooks/useAccountManager'
import { GlobalContext } from '../../state/reducer'
import useWeighbridgesInfo, { Weighbridge } from '../../hooks/useWeighbridgesInfo'
import TextWithLabel from './../common/TextWithLabel'
import CheckboxEditWithLabel from './../common/CheckboxEditWithLabel'

const EditWeighbridgeAccess: React.FC<Role> = (props) => {
  const { uid, scope } = props
  const { state } = React.useContext(GlobalContext)
  const { user: adminUser } = state
  const manage = useAccountManager(adminUser)
  const weighbridges = useWeighbridgesInfo()
  const [editingScope, setEditingScope] = React.useState(false)
  const toggleEditScope = React.useCallback(() => setEditingScope(!editingScope), [editingScope])
  const onUpdateScope = React.useCallback((newScope: string[]) => manage.setWeighbridgeAccess(uid, newScope), [manage, uid])
  const weighbridgesInScope: (Weighbridge | undefined)[] = scope ? scope.map(id => weighbridges.find(item => item.uid === id)) : []

  return (
    <>
      {manage.lastError && <span className="Error">{manage.lastError.message}</span>}
      {!editingScope && (
        <TextWithLabel label="Scope">
          <div className="ScopeList">
            {weighbridgesInScope.map((item, index) => <span key={item?.uid || index} title={item?.uid}>{item?.name}</span>)}
          </div>
          <span className="clickable" onClick={toggleEditScope}>Edit</span>
        </TextWithLabel>
      )}
      {editingScope && (
        <CheckboxEditWithLabel label="Scope" value={''} options={weighbridges} selections={scope || []} onChange={onUpdateScope}>
          <span className="clickable" onClick={toggleEditScope}>Done</span>
        </CheckboxEditWithLabel>
      )}
    </>
  )
}

export default EditWeighbridgeAccess