import React from 'react'
import air from '../state/firebaseApp'
import { RoleTypes } from './useAllRoles'

export type NewUserType = {
  email: string
  name: string
  password: string
  role: RoleTypes
  scope: string[]
}

const requestFirebaseFunction = async (authenticatedUser: firebase.User, action: 'createUser' | 'changePassword' | 'deactivateAccount', payload: any) => {
  try {
    const token = await authenticatedUser.getIdToken()
    const response = await fetch(`https://us-central1-logr-air.cloudfunctions.net/${action}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, payload })
    })

    if (response.status === 200) {
      const result = await response.json()
      console.log('Success', result)
      return result
    } else {
      const errorMessage = await response.text()
      console.error('errorMessage', response.status, response.body, errorMessage)
      throw new Error(errorMessage)
    }
  } catch (error) {
    console.error('createAccount error', error)
    return Promise.reject(error)
  }
}

const useAccountManager = (user: firebase.User | null) => {
  const [lastError, setLastError] = React.useState<Error>()
  const sendVerificationEmail = async () => await user?.sendEmailVerification()
  const createAccount = React.useCallback(async (newUser: NewUserType) => {
    setLastError(undefined)
    try {
      if (!user) {
        throw new Error('User not logged in')
      }
  
      const { email, name, password, role, scope: sourceScope } = newUser
  
      type UserRecordResponse = { uid: string, email: string, displayName: string, disabled: boolean }
      const userRecord: UserRecordResponse = await requestFirebaseFunction(user, 'createUser', { email, password, displayName: name })
  
      if (userRecord) {
        const uid = userRecord.uid
        await createRole(uid, email, name, role, sourceScope)
      }
    } catch (error) {
      console.error(error)
      setLastError(error)
    }
  }, [user])

  const createRole = async (uid: string, email: string, name: string, role: RoleTypes, scope?: string[]) => {
    setLastError(undefined)
    const payload: any = {
      email,
      name,
      role
    }

    if (scope) {
      payload.scope = scope
    }

    return await air.firestore().collection('roles').doc(uid).set(payload, { merge: true })
  }

  const changePassword = React.useCallback(async (uid: string, password: string) => {
    setLastError(undefined)
    try {
      if (!user) {
        throw new Error('User not logged in')
      }
  
      // type UserRecordResponse = { uid: string, email: string, displayName: string, disabled: boolean }
      return await requestFirebaseFunction(user, 'changePassword', { uid, password })
    } catch (error) {
      console.error(error)
      setLastError(error)
    }
  }, [user])

  const deactivateAccount = React.useCallback(async (uid: string) => {
    setLastError(undefined)
    try {
      if (!user) {
        throw new Error('User not logged in')
      }
  
      // type UserRecordResponse = { uid: string, email: string, displayName: string, disabled: boolean }
      return await requestFirebaseFunction(user, 'deactivateAccount', { uid })

    } catch (error) {
      console.error(error)
      setLastError(error)
    }
  }, [user])

  const changeName = React.useCallback(async (uid: string, name: string, role: RoleTypes) => {
    setLastError(undefined)
    if (!user) {
      throw new Error('User not logged in')
    }

    // TODO: Firebase Authentication Display Name: This needs to be done with a Firebase function as the function will have admin access. We don't want admin access credentials on frontend.

    if (role === 'weighbridge') {
      await air.firestore().collection('weighbridges').doc(uid).set({ name }, { merge: true })
    }

    return air.firestore().collection('roles').doc(uid).set({ name }, { merge: true })
  }, [user])

  const changeEmail = React.useCallback(async (uid: string, email: string, role: RoleTypes) => {
    setLastError(undefined)
    if (!user) {
      throw new Error('User not logged in')
    }

    // TODO: Firebase Authentication Display Name: This needs to be done with a Firebase function as the function will have admin access. We don't want admin access credentials on frontend.

    if (role === 'weighbridge') {
      await air.firestore().collection('weighbridges').doc(uid).set({ email }, { merge: true })
    }

    return air.firestore().collection('roles').doc(uid).set({ email }, { merge: true })
  }, [user])

  const changeWeighbridgeLocationName = React.useCallback(async (uid: string, location: string, role: RoleTypes) => {
    setLastError(undefined)
    if (!user) {
      throw new Error('User not logged in')
    }

    if (role === 'weighbridge') {
      return air.firestore().collection('weighbridges').doc(uid).set({ location }, { merge: true })
    }
  }, [user])

  const setWeighbridgeAccess = React.useCallback(async (uid: string, weighbridgeUids: string[]) => {
    setLastError(undefined)
    if (!user) {
      throw new Error('User not logged in')
    }

    return air.firestore().collection('roles').doc(uid).set({ scope: weighbridgeUids }, { merge: true })
  }, [user])

  const setRole = React.useCallback(async (uid: string, role: string) => {
    setLastError(undefined)
    if (!user) {
      throw new Error('User not logged in')
    }

    return air.firestore().collection('roles').doc(uid).set({ role }, { merge: true })
  }, [user])

  return {
    account: createAccount,
    createRole,
    password: changePassword,
    deactivate: deactivateAccount,
    changeName,
    changeEmail,
    locationName: changeWeighbridgeLocationName,
    setWeighbridgeAccess,
    role: setRole,
    sendVerificationEmail,
    lastError,
  }
}

export default useAccountManager