import React, { ChangeEvent } from 'react'
import { TextWithLabelProps } from './TextWithLabel'

interface TextEditWithLabelProps extends TextWithLabelProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const TextEditWithLabel: React.FC<TextEditWithLabelProps> = ({ label, value, onChange, children }) => (
  <label className="TextWithLabel">
    <span className="label">{label}</span>
    {value && <input value={value} onChange={onChange} />}
    {children}
  </label>
)

export default TextEditWithLabel