import React from 'react'
import classNames from 'classnames'
import useLocalStorage from '../../hooks/useLocalStorage'
import useFirebaseAuth from '../../hooks/useFirebaseAuth'
import TextInput from '../common/TextInput/TextInput'
import Logo from '../common/Logo'
import './Login.scss'
import * as EmailValidator from 'email-validator'

type LoginProps = {
  // authStateChanged: (user: firebase.User|null) => void
  firebaseApp: firebase.app.App
  expectSignedIn: boolean // Whether the previous state things we're logged in
}

const Login: React.FC<LoginProps> = ({ firebaseApp, expectSignedIn }) => {
  const [email, setEmail] = useLocalStorage('email', '')
  const [password, setPassword] = React.useState('')

  const [signInPressed, setSignInPressed] = React.useState(false)
  const emailValid = React.useMemo(() => EmailValidator.validate(email), [email])

  // TODO: This is really bad, login should not be triggered on data state changes. It can cause issues if you go back and start editing the email whilst there is a password.
  const [fetching, error] = useFirebaseAuth(firebaseApp, email, (signInPressed && emailValid) ? password : null)
  const signInHandler = React.useCallback(() => setSignInPressed(true), [])

  return (
    <form className="Login" onSubmit={e => { e.preventDefault(); signInHandler() }}>
      <Logo height={80} />
      {expectSignedIn && <span>Loading...</span>}
      {expectSignedIn === false && (
        <div className="form entered">
          <TextInput
            value={email}
            onChange={setEmail}
            enabled={!fetching}
            label="Email"
            orientation="vertical"
          />
          <TextInput
            value={password}
            onChange={setPassword}
            enabled={!fetching}
            label="Password"
            type="password"
          />
          <div style={{ marginTop: '30px' }}>
            <button className={classNames('wide button', { disabled: fetching })} onClick={signInHandler}>
              Log in
          </button>
          </div>
        </div>
      )}

      {error && <span className="Error">{error.message}</span>}
    </form>
  )
}

export default Login