import React from 'react'
import TextInput from './common/TextInput/TextInput'
import useRoles, { RoleTypes, Role } from '../hooks/useRoles'
import useAccountManager, { NewUserType } from '../hooks/useAccountManager'
import { GlobalContext } from '../state/reducer'
import * as EmailValidator from 'email-validator'
import { Button } from './common/Button/Button'
import DropDown from './common/DropDown/DropDown'
import MultiSelect from './common/MultiSelect/MultiSelect'

type ChangeType = [string, string | string[]]

const userReducer = (prev: NewUserType, change: ChangeType): NewUserType => {
  const key = change[0]
  const value = change[1]

  return {
    ...prev,
    [key]: value
  }
}

const roles: RoleTypes[] = [
  // 'unknown',
  'subscriber',
  'terminal',
  'weighbridge',
  'identity',
  'debug',
]

const NewUser = () => {
  const [user, setUser] = React.useReducer(userReducer, { email: '', name: '', password: '', role: 'unknown', scope: [] })
  const { state } = React.useContext(GlobalContext)
  const { user: adminUser } = state
  const manage = useAccountManager(adminUser)
  const weighbridges = useRoles('weighbridge')
  const unassignedUsers = useRoles('unknown')

  const { email, name, password, role, scope } = user

  const emailValid = EmailValidator.validate(email)
  const setEmail = React.useCallback(value => setUser(['email', value]), [])
  const setName = React.useCallback(value => setUser(['name', value]), [])
  const setPassword = React.useCallback(value => setUser(['password', value]), [])
  const setRole = React.useCallback(value => setUser(['role', value]), [])
  const onWeighbridgeChange = React.useCallback((checked: boolean, uid: string) => {
    const filtered = scope.filter(existing => existing !== uid)
    setUser(['scope', checked ? [...filtered, uid] : filtered])
  }, [scope])
  const [fetching, setFetching] = React.useState(false)
  const onCreate = React.useCallback(() => {
    setFetching(true)
    manage.account(user)
      .finally(() => setFetching(false))
  }, [user, manage])
  const isValidUser = React.useMemo(() => {
    if (!emailValid) { 
      return false
    }
    if (name.trim().length === 0) {
      return false
    }
    if (password.trim().length === 0) {
      return false
    }
    if (!roles.includes(role)) {
      return false
    }
    return true
  }, [emailValid, name, password, role])

  return (
    <div className="NewUser">
      {manage.lastError && <span className="Error">{manage.lastError.message}</span>}

      <TextInput value={email} onChange={setEmail} enabled={true} label="Email" orientation="vertical" valid={emailValid}/>
      <TextInput value={name} onChange={setName} enabled={true} label="Name" orientation="vertical" />
      <TextInput value={password} type="password" onChange={setPassword} enabled={true} label="Password" orientation="vertical" />
 
      <DropDown label="Role" value={role} onChange={setRole}>
        <option value="">[Please select]</option>
        {roles.map(item => <option key={item} value={item}>{item}</option>)}
      </DropDown>

      {(role === 'terminal' || role === 'subscriber') && (
        <MultiSelect
          label="Weighbridges"
          options={weighbridges.reduce((prev, next) => ({ ...prev, [next.uid]: next.name }), {} as any)}
          values={scope}
          onChange={onWeighbridgeChange}
        />
      )}
      
      <Button disabled={!isValidUser || fetching} onClick={onCreate}>
        {fetching && "Creating..."}
        {!fetching && "Create"}
      </Button>

      <pre style={{ whiteSpace: 'pre' }}>{JSON.stringify(user, null, 2)}</pre>

      {unassignedUsers.length > 0 && <h4>Unassigned Accounts</h4>}
      {unassignedUsers.map(item => <UnassignedUser key={item.uid} {...item}/>)}
    </div>
  )
}

const UnassignedUser: React.FC<Role> = (props) => {
  const { uid, name, role: sourceRole } = props
  const { state } = React.useContext(GlobalContext)
  const { user: adminUser } = state
  const [role, setRole] = React.useState<string>(sourceRole)

  const manage = useAccountManager(adminUser)
  const create = React.useCallback(() => {
    manage.createRole(uid, 'unknown email', name || uid, role as  RoleTypes)
  }, [uid, name, manage, role])

  return (
    <div className="UnassignedUser">
      <span>{props.name}</span>
      <DropDown label="Role" value={role} onChange={setRole}>
        <option value="">[Please select]</option>
        {roles.map(item => <option key={item} value={item}>{item}</option>)}
      </DropDown>
      <Button onClick={create}>Create</Button>
    </div>
  )
}

export default NewUser