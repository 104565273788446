import React from 'react'

export type TextWithLabelProps = {
  label: string
  value?: string
}

const TextWithLabel: React.FC<TextWithLabelProps> = ({ label, value, children }) => (
  <label className="TextWithLabel">
    <span className="label">{label}</span>
    {value && <span>{value}</span>}
    {children}
  </label>
)

export default TextWithLabel