import React from 'react'
import firebase from 'firebase'
import 'firebase/auth'

type useFirebaseAuthType = (
  firebaseApp: firebase.app.App,
  email: string,
  password: string | null
) => [boolean, Error?]

const useFirebaseAuth: useFirebaseAuthType = (firebaseApp, email, password) => {
  const [fetching, setFetching] = React.useState<boolean>(false)
  const [error, setError] = React.useState<Error>()

  React.useEffect(() => {
    if (email.length > 0 && typeof password === 'string' && password.length > 0) {
      setFetching(true)
      setError(undefined)
      const currentUser = firebaseApp.auth().currentUser

      if (currentUser) {
        console.warn(`User already logged in ${currentUser.email}`)
      } else {
        firebaseApp.auth()
          .signInWithEmailAndPassword(email, password)
          .catch(setError)
          .finally(() => setFetching(false))
      }
    }

  }, [firebaseApp, email, password])

  return [fetching, error]
}

export default useFirebaseAuth