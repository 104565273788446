import React from 'react'
import classNames from 'classnames'

import './TextInput.scss'

type TextInputProps = {
  label?: string
  placeholder?: string
  value: string
  enabled: boolean
  onChange: (val: string) => void
  orientation?: 'vertical' | 'horizontal'
  type?: 'password' | 'text'
  inputStyle?: 'rounded',
  valid?: boolean
}

const TextInput: React.FC<TextInputProps> = ({ label, value, enabled, onChange, orientation, type, placeholder, inputStyle, valid }) => {
  const warningClass = valid === undefined || valid === true ? '' : 'Invalid'

  return (
    <label className={classNames('TextInput', orientation ?? 'vertical', inputStyle, warningClass)}>
      {label && <span className="label">{label}</span>}
      <input
        type={type ?? 'text'}
        disabled={!enabled}
        value={value ?? ''}
        placeholder={placeholder ?? label}
        onChange={e => onChange(e.target.value)}
      />
    </label>
  )
}

export default TextInput