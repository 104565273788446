import React from 'react'
import { Role } from '../../hooks/useRoles'
import useDebounceValue from '../../hooks/useDebounceValue'
import TextWithLabel from './TextWithLabel'
import TextEditWithLabel from './TextEditWithLabel'

interface UserTextFieldEditorProps extends Role {
  label: string
  value: string
  onCommit: (value: string) => void
}

const UserTextFieldEditor: React.FC<UserTextFieldEditorProps> = (props) => {
  const { label, value, onCommit } = props
  const [editing, setEditing] = React.useState(false)
  const toggleEditing = React.useCallback(() => setEditing(!editing), [editing])
  const [updatedValue, setUpdatedValue] = React.useState<string>(value)
  const debouncedValue = useDebounceValue(updatedValue, 1000)

  React.useEffect(() => {
    if (debouncedValue !== value) {
      onCommit(debouncedValue)
    }
  }, [debouncedValue, value, onCommit])

  return (
    <>
      {!editing && (
        <TextWithLabel label={label} value={value || 'not set'}>
          <span className="clickable" onClick={toggleEditing}>Edit</span>
        </TextWithLabel>
      )}
      {editing && (
        <TextEditWithLabel label={label} value={updatedValue} onChange={e => setUpdatedValue(e.target.value)}>
          <span className="clickable" onClick={toggleEditing}>Done</span>
        </TextEditWithLabel>
      )}
    </>
  )
}

export default UserTextFieldEditor