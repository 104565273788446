import React from 'react'
import User from './User'
import useRoles from '../hooks/useRoles'

const Terminal = () => {
  const roles = useRoles('terminal')

  return (
    <div className="Terminal">
      {roles.map(role => (
        <User key={role.uid} {...role}/>
      ))}
    </div>
  )
}

export default Terminal