import React from 'react'
import classNames from 'classnames'
import './MultiSelect.scss'

type MultiSelectProps = {
  label?: string
  options: { [key: string]: string }
  values: string[] // Selected Keys
  inputStyle?: 'rounded'
  valid?: boolean
  onChange: (checked: boolean, key: string) => void
}

const MultiSelect: React.FC<MultiSelectProps> = ({ label, options, values, inputStyle, valid, onChange }) => {
  const warningClass = valid === undefined || valid === true ? '' : 'Invalid'
  const keys = Object.keys(options)

  return (
    <div className={classNames('MultiSelect', inputStyle, warningClass)}>
      {label && <span className="label">{label}</span>}
      {keys.map(key => (
        <label key={key}>
          <input key={key} type="checkbox" checked={values.includes(key)} onChange={(e) => onChange(e.target.checked, key)}/>
          <span>{options[key]}</span>
        </label>
      ))}
    </div>
  )
}

export default MultiSelect