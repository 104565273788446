import React from 'react'
import { GlobalContext, AUTH_STATE_CHANGED } from '../state/reducer'
import Login from './Login/Login'
import air from '../state/firebaseApp'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import useFirebaseAuthPeristed from '../hooks/useFirebaseAuthPeristed'
import Header, { HeaderNavItem } from './common/Header/Header'
import PrivateRoute from './PrivateRoute'
import ApiKeys from './ApiKeys'
import IdentityProviders from './IdentityProviders'
import Weighbridges from './Weighbridges'
import Subscribers from './Subscribers'
import Terminal from './Terminal'
import Debuggers from './Debuggers'
import NewUser from './NewUser'
// import useRoles from '../hooks/useRoles'

const LogrAirCredentialsManager = () => {
  const { state, dispatch } = React.useContext(GlobalContext)
  const { user, expectSignedIn } = state
  const history = useHistory()
  const location = useLocation()
  const firebaseUser = useFirebaseAuthPeristed(air)

  React.useEffect(() => {
    dispatch({ type: AUTH_STATE_CHANGED, user: firebaseUser })
  }, [dispatch, firebaseUser])
  
  React.useEffect(() => {
    if (user) {
      const pathname = (location.state as any)?.from.pathname

      if (pathname) {
        history.replace({ pathname })
      }
    }
  }, [user, history, location.state])

  const privateNavItems: HeaderNavItem[] = React.useMemo(() => ([
    { label: 'New', path: '/new', action: () => history.push('/new') },
    { label: 'Weighbridges', path: '/weighbridges', action: () => history.push('/weighbridges') },
    { label: 'Identity Providers', path: '/identityProviders', action: () => history.push('/identityProviders') },
    { label: 'Subscribers', path: '/subscribers', action: () => history.push('/subscribers') },
    { label: 'Terminal', path: '/terminal', action: () => history.push('/terminal') },
    // { label: 'API Keys', path: '/apiKeys', action: () => history.push('/apiKeys') },
    { label: 'Debuggers', path: '/debuggers', action: () => history.push('/debuggers') },
    { label: 'Sign out', path: '/signout', action: () => air.auth().signOut().then(() => dispatch({ type: AUTH_STATE_CHANGED, user: null }))},
  ]), [dispatch, history])

  return (
    <>
      {!user && <Header navItems={[]} current={location.pathname} />}
      {user && <Header navItems={privateNavItems} current={location.pathname} />}

      <Switch>
        <Route exact path={['/', '/login']}>
          <Login firebaseApp={air} expectSignedIn={expectSignedIn} />
        </Route>
        <PrivateRoute exact path="/new">
          <NewUser />
        </PrivateRoute>
        <PrivateRoute exact path="/weighbridges">
          <Weighbridges />
        </PrivateRoute>
        <PrivateRoute exact path="/identityProviders">
          <IdentityProviders />
        </PrivateRoute>
        <PrivateRoute exact path="/subscribers">
          <Subscribers />
        </PrivateRoute>
        <PrivateRoute exact path="/terminal">
          <Terminal />
        </PrivateRoute>
        <PrivateRoute exact path="/debuggers">
          <Debuggers />
        </PrivateRoute>
        <PrivateRoute exact path="/apiKeys">
          <ApiKeys />
        </PrivateRoute>
      </Switch>
    </>
  )
}

export default LogrAirCredentialsManager