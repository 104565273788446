import React from 'react'
import { RoleTypes } from '../../hooks/useRoles'

const RoleSelector = () => {
  const roles: RoleTypes[] = [
    // 'unknown',
    'subscriber',
    'terminal',
    'weighbridge',
    'identity',
    'debug',
  ]

  return (
    <label>
      <span>Select role</span>
      <select>
        {roles.map(item => <option key={item}>{item}</option>)}
      </select>
    </label>
  )
}


export default RoleSelector