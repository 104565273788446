import React from 'react'
import CloudReducer, { QuerySnapshot } from './CloudReducer'
import air from '../state/firebaseApp'

export type Weighbridge = {
  uid: string
  name?: string
  location?: string
}

const reducer = (prev: Weighbridge[], action: QuerySnapshot|null) => CloudReducer(prev, action)

const useWeighbridgesInfo = () => {
  const [weighbridges, setWeighbridge] = React.useReducer(reducer, [])

  React.useEffect(() => {
    return air.firestore()
      .collection('weighbridges')
      .onSnapshot(setWeighbridge)

  }, [])

  return [...weighbridges].sort((a, b) => {
    if (a.name && b.name) {
      return a.name.localeCompare(b.name)
    }

    return a.uid.localeCompare(b.uid)
  })
}

export default useWeighbridgesInfo