import React from 'react'
import classNames from 'classnames'
import './DropDown.scss'

type DropDownProps = {
  label?: string
  value: string
  enabled?: boolean
  onChange?: (val: string) => void
  inputStyle?: 'rounded'
  valid?: boolean
}

const DropDown: React.FC<DropDownProps> = ({ label, value, enabled, onChange, inputStyle, valid, children }) => {
  const warningClass = valid === undefined || valid === true ? '' : 'Invalid'

  return (
    <label className={classNames('DropDown', inputStyle, warningClass)}>
      {label && <span className="label">{label}</span>}
      <select disabled={enabled === false} value={value ?? ''} onChange={e => onChange && onChange(e.target.value)}>
        {children}
      </select>
    </label>
  )
}

export default DropDown