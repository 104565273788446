import React from 'react'
import { TextWithLabelProps } from './TextWithLabel'
import { Weighbridge } from '../../hooks/useWeighbridgesInfo'

interface CheckboxEditWithLabelProps extends TextWithLabelProps {
  onChange: (selections: string[]) => void
  options: Weighbridge[]
  selections: string[]
}

const CheckboxEditWithLabel: React.FC<CheckboxEditWithLabelProps> = ({ label, onChange, options, selections, children }) => {
  const onToggle = React.useCallback((opt: string) => () => {
    if (selections.includes(opt)) {
      onChange(selections.filter(item => item !== opt))
    } else {
      onChange([...selections, opt])
    }
  }, [onChange, selections])

  return (
    <div className="CheckboxEditWithLabel">
      <span className="label">{label}</span>
      <div>
        {options.map(role => (
          <label key={role.uid}>
            <input type="checkbox" value={role.uid} checked={selections.includes(role.uid)} onChange={onToggle(role.uid)} />
            <span title={role?.uid}>{role.name}</span>
          </label>
        ))}
      </div>
      {children}
    </div>
  )
}

export default CheckboxEditWithLabel