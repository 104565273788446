import React from 'react'
import classNames from 'classnames'
import './Button.scss'

interface BaseButtonProps {
  onClick?: () => void
  children: React.ReactNode
  disabled?: boolean
}

interface ButtonProps extends BaseButtonProps {
  type?: 'danger' | 'default'
}

export const GenericButton: React.FC<ButtonProps> = ({
  onClick,
  children, 
  type,
  disabled,
}) => (
  <button
    onClick={() => onClick?.()}
    className={classNames('button', type)}
    disabled={disabled}
  >
    {children}
  </button>
)

export const Button: React.FC<BaseButtonProps> = (props) => (
  <GenericButton {...props} />
)

export const DangerButton: React.FC<BaseButtonProps> = (props) => (
  <GenericButton {...props} type="danger" />
)

export default Button