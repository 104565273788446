import React from 'react'
import './Weighbridges.css'
import useRoles from '../hooks/useRoles'
import useWeighbridgesInfo, { Weighbridge } from '../hooks/useWeighbridgesInfo'
import useAccountManager from '../hooks/useAccountManager'
import { GlobalContext } from '../state/reducer'
import User from './User'
import { Button } from './common/Button/Button'

const Weighbridges = () => {
  const roles = useRoles('weighbridge')
  const weighbridges = useWeighbridgesInfo()
  const existingRoleUids = roles.map(item => item.uid)
  const missingRoles = weighbridges.filter(item => !existingRoleUids.includes(item.uid))

  return (
    <div className="Weighbridges">
      {roles.map(role => (
        <User key={role.uid} {...role} />
      ))}

      {missingRoles.map(item => <UnmatchWeighbridge key={item.uid} {...item} />)}
    </div>
  )
}

const UnmatchWeighbridge: React.FC<Weighbridge> = (props) => {
  const { uid, name, location } = props
  const { state } = React.useContext(GlobalContext)
  const { user: adminUser } = state
  const manage = useAccountManager(adminUser)
  const create = React.useCallback(() => manage.createRole(uid, 'unknown', name || location || uid, 'weighbridge'), [uid, name, location, manage])

  return (
    <>
      {manage.lastError && <span className="Error">{manage.lastError.message}</span>}
      <div className="UnmatchWeighbridge">
        <span>{props.name}</span>
        <span>({props.location})</span>
        <Button onClick={create}>Create</Button>
      </div>
    </>
  )
}

export default Weighbridges