import React from 'react'
import { Role } from '../hooks/useRoles'
import TextWithLabel from './common/TextWithLabel'
import UserTextFieldEditor from './common/UserTextFieldEditor'
import EditWeighbridgeAccess from './common/EditWeighbridgeAccess'
import useAccountManager from '../hooks/useAccountManager'
import { GlobalContext } from '../state/reducer'
import RoleSelector from '../components/common/RoleSelector'

const User: React.FC<Role> = (userRole) => {
  const { uid, name, role } = userRole
  const { state } = React.useContext(GlobalContext)
  const { user: adminUser } = state
  const manage = useAccountManager(adminUser)
  const onCommitName = React.useCallback((newName: string) => manage.changeName(uid, newName, role), [manage, uid, role])
  // const onCommitEmail = React.useCallback((newName: string) => manage.changeEmail(uid, newName, role), [manage, uid, role])

  return (
    <>
      {manage.lastError && <span className="Error">{manage.lastError.message}</span>}

      <div className="User">
        <TextWithLabel label="UID" value={uid || 'not set'} />
        {/* <UserTextFieldEditor {...userRole} label="Email" value={email || 'not set'} onCommit={onCommitEmail}/> */}
        <UserTextFieldEditor {...userRole} label="Name" value={name || 'not set'} onCommit={onCommitName} />
        {/* <TextWithLabel label="Role" value={role} /> */}
        {role === 'unknown' && <RoleSelector />}
        {(role === 'subscriber' || role === 'terminal') && <EditWeighbridgeAccess {...userRole} />}

      </div>
    </>
  )
}

export default User